import * as React from "react"
import { Layout } from "../components/layout"
import { heading, paragraph, container } from "./404.module.css"
import { Seo } from "../components/seo"

export default function Contact() {
  return (
    <Layout>
      <div className="w-5/6 md:w-3/5 pt-[20vh] md:pt-[20px]  mx-auto">
        <h1 className={heading}>About</h1>
<br />

        <p>Yori is a lifestyle.</p>

<p className="pt-[20px]">Black label</p>

<p>Avante garde pieces built into the YORI uniform with close attention to detail, luxury fabrics, pricing ,and manufactured in the garment district in New York City</p>
<br />
      </div>
    </Layout>
  )
}

export const Head = () => <Seo />
